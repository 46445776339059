import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ['link'];
  }

  static get values() {
    return { action: String, body: Object, fileName: String }
  }

  download(event) {
    event.preventDefault();

    fetch(this.actionValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.bodyValue)
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.blob();
    })
    .then(blob => {
      // Create a blob URL and initiate the download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = this.fileNameValue; // You may change the filename
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    })
    .catch(error => console.error('Fetch error:', error));
  }
}
