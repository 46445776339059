import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ['currentDocumentId', 'documentList', 'searchResults', 'document', 'expander'];
  }

  static get values() {
    return {
      expandedSections: Boolean,
      searchUrl: String,
      currentSection: String
    };
  }

  static get outlets() {
    return ['search-field'];
  }

  documentListTargetConnected() {
    if (Object.keys(this.togglerStates).length === 0) {
      this.captureState()
    }
  }

  initialize() {
    // this is resetting the state when the controller connects - but this could be a nice way to persist
    // the state across page reloads as well
    this.toggleStatesKey = window.location.pathname + '-toggle-states';
    localStorage.setItem(this.toggleStatesKey, JSON.stringify({toggler_states: {}}))
  }

  connect() {
    this._handleSidebarScroll = () => {
      const sidebar = document.querySelector('#side-bar-scroll');
      if (!sidebar) return;
      const sidebarOffset = 57;
      const scrollPosition = window.scrollY || window.pageYOffset || 0;
      const sidebarPosition = sidebar.offsetTop - sidebarOffset;
      scrollPosition >= sidebarPosition ? sidebar.classList.add('fix-sidebar') : sidebar.classList.remove('fix-sidebar');
    }

    if (this.expandedSectionsValue) {
      this.highlightSection();
    }
    this.expandToLinkedSection();
    this.setTocHeight();
    this.scrollToCurrentDocument();
  }

  disconnect() {
    window.removeEventListener('scroll', this._handleSidebarScroll);
    localStorage.removeItem(this.toggleStatesKey)
  }

  documentTargetConnected() {
    if (this.currentSectionValue) {
      this.expandToCurrentSection();
    }
  }

  /*
    When a section of the TOC is broadcasted, we want to put it back in the expanded
    state it was in pre-broadcast.
  */
  expandToCurrentSection() {
    const matchedSectionLink = $('a[href="' + this.currentSectionValue + '"]');
    if (matchedSectionLink.length) {
      matchedSectionLink.addClass('active').parentsUntil('.parentTitle').siblings().closest('div.todo-sidebar-section-sub-sub-section-toggler').not('.open-menu').click();
    }
  }

  searchFieldOutletConnected(outlet) {
    outlet.registerSearchCallback(() => this.handleSearchInput(outlet))
  }

  handleSearchInput(outlet) {
    if (this.searchFieldOutlet.inputTarget.value) {
      this.sendSearchRequest();
    } else {
      this.showDocumentList();
    }
  }

  searchQueryString() {
    return new URLSearchParams({
      search: this.searchFieldOutlet.inputTarget.value,
      current_document: this.currentDocumentIdTarget.value
    });
  }

  searchUrlWithQueryString() {
    return `${this.searchUrlValue}?${this.searchQueryString()}`
  }

  sendSearchRequest() {
    fetch(this.searchUrlWithQueryString(), {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
      }
    })
      .then(resp => resp.text())
      .then(html => {
        Turbo.renderStreamMessage(html);
        this.hideDocumentList();
      })
      .catch(error => {
        console.log(error);
        this.showDocumentList();
      });
  }

  showDocumentList() {
    this.documentListTarget.classList.remove('d-none');
    this.searchResultsTarget.classList.add('d-none');
  }

  hideDocumentList() {
    this.documentListTarget.classList.add('d-none');
  }

  /*
    When a `same-page-section-link` is clicked, find the arrow toggler sibling, if one exists,
    and click it. This will expand this section and display its children.
  */
  expand(event) {
    const link = event.currentTarget;
    this.handleExpand(link);
  }

  handleExpand(ele) {
    const toggler = ele.parentElement.querySelector('.todo-sidebar-section-sub-sub-section-toggler');
    if (toggler) {
      toggler.click();
    }
  }

  /*
    When a toggle arrow of a sub-section with children is clicked, expand and display
    the container for its children.
  */
  toggleSubSection(event) {
    const $toggler = $(event.currentTarget);
    $toggler.closest('.todo-sidebar-section-sub-section').find('.todo-sidebar-section-sub-section-content:first').slideToggle(100);
    this.toggleArrowIcon($toggler);
  }

  /*
    When a toggle arrow of a sub-sub-section with children is clicked, expand and display
    the container for its children.
  */
  toggleSubSubSection(event) {
    const $toggler = $(event.currentTarget);
    $toggler.closest('.todo-sidebar-section-sub-sub-section').find('.todo-sidebar-section-sub-sub-section-content:first').slideToggle(100);
    this.toggleArrowIcon($toggler);
  }

  /*
    Checks to see if a specific section is being linked to, and if so, expands to TOC to that section.

    NOTE: This code was left as-is from the JS originally in the TOC view.
  */
  expandToLinkedSection() {
    const urlAnchor = $(location).attr('hash');
    if (!urlAnchor.length) { return; }
    this.currentSectionValue = urlAnchor
    const matchedSectionLink = $('a[href="' + urlAnchor + '"]');
    if (matchedSectionLink.length) {
      matchedSectionLink.addClass('active').parentsUntil('.parentTitle').siblings().closest('div.todo-sidebar-section-sub-sub-section-toggler').not('.open-menu').click();
    }
  }

  /*
    This will scroll the TOC to the current document. Only really matters if there are a lot
    of documents in the TOC.

    NOTE: This code was left as-is from the JS originally in the TOC view.
  */
  scrollToCurrentDocument() {
    const $container = $(".todo-app-w");
    let $scrollTo = $('li a.active');
    if ($scrollTo.length > 0) {
      $scrollTo = ($scrollTo.length > 1) ? $scrollTo.last() : $scrollTo;
      $container.animate({ scrollTop: ($scrollTo.offset().top - 200) - $container.offset().top, scrollLeft: 0 }, 0);
    }
  }

  /*
    The container for the TOC is a bit shorter than the page height.
    When the page is scrolled all the way to the top, shorten the TOC so that
    users can still scroll through all content of the TOC. When the page is
    scrolled past the top of the TOC, expand the TOC to be the height of the page.

    NOTE: This code was left as-is from the JS originally in the TOC view.
  */
  setTocHeight() {
    $(window).on('scroll', this._handleSidebarScroll);
  }

  /*
    The purpose of this code is to highlight a section link in the TOC when it is
    scrolled over in the document.

    NOTE: This code was left as-is from the JS originally in the TOC view.
  */
  highlightSection() {
    const controller = this;
    $(document).on('scroll', () => {
      let scrollTop = $(document).scrollTop();

      $('.section-container').each(function () {
        // Adding - 200 because of the top navbar that has an offset already
        let sectionTop = $(this).offset().top - 200;
        let sectionBottom = sectionTop + $(this).height();
        let sectionId = $(this).attr('id');

        // 1) -1 is needed because when a user clicks on the TOC link the scrollTop is actually 1 px above the top
        //    of the target section. Without this the section above the target one will be active which isn't right.
        // 2) Check bottom as well so that we can inactivate the last section when the user scrolls outside and below
        //    the last section
        if (scrollTop >= sectionTop - 1 && scrollTop <= sectionBottom) {
          controller.currentSectionValue = `#${sectionId}`;
          // This can be optimized further by caching the mappings of sections and section links in jQuery
          let matchingSectionLink = $('a.same-page-section-link[href="#' + sectionId + '"]');
          if (!matchingSectionLink.hasClass('active')) {
            $(document).find('a.active').not(this).removeClass('active');
            if (matchingSectionLink.closest('ul').css('display') == "block") {
              matchingSectionLink.addClass('active');
            } else {
              // For getting the matching parent open section while current section (matched) is not in open state
              let get_open_parent = matchingSectionLink.parents('ul').filter(function () {
                return $(this).css('display') == 'block';
              }).first().children('li').has(matchingSectionLink);
              get_open_parent.children('a').addClass('active');
            }
          }
          return true;
        }
      });
    })
  }

  /*
    Toggle the `open-menu` class. When the class is applied, the arrow is darker and is pointing down,
    signifying that the corresponding section is expanded.
    Otherwise it is lighter and pointing to the right, signifying the corresponding section is collapsed.
  */
  toggleArrowIcon($toggler) {
    $toggler.toggleClass('open-menu');
    if (!this.skipStateChange) {
      this.captureState();
    }
  }

  expanderTargetConnected(expander) {
    this.skipStateChange = true;
    if (this.togglerStates[expander.id]) {
      this.handleExpand(expander);
    }
    this.skipStateChange = false;
  }

  get stateFromStorage() {
    return JSON.parse(localStorage.getItem(this.toggleStatesKey)) || {toggler_states: {}};
  }

  get togglerStates() {
    return this.stateFromStorage['toggler_states'];
  }

  captureState() {
    const togglerStates = {};
    this.expanderTargets.forEach( expander => {
      let toggler = expander.parentElement.querySelector('.todo-sidebar-section-sub-sub-section-toggler')
      if (toggler) {
        togglerStates[expander.id] = toggler.classList.contains('open-menu');
      }
    })
    this._setToggleStates(togglerStates);
  }

  _setToggleStates(state, withRetry = true) {
    try {
      localStorage.setItem(this.toggleStatesKey, JSON.stringify({ toggler_states: state }))
    } catch (e) {
      if (withRetry) {
        const removableKeys = Object.keys(localStorage).filter(k => k.includes('toggle-states'))
        removableKeys.forEach(k => localStorage.removeItem(k))
        this._setToggleStates(state, false)
      }
    }
  }
}
